<template>
    <div class="generate-certificate">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
            <el-breadcrumb-item>评价中心</el-breadcrumb-item>
            <el-breadcrumb-item>证书模板</el-breadcrumb-item>
            <el-breadcrumb-item>未获得证书</el-breadcrumb-item>
            <el-breadcrumb-item>生成证书</el-breadcrumb-item>
        </el-breadcrumb>
        <el-scrollbar class="certificate-content" :native="false">
            <div class="certificate-content-item">
                <el-form :model="certificateForm" ref="certificateForm" :rules="rules" label-position="right" label-width="240px" :hide-required-asterisk="true">
                    <div class="certificate-info">
                        <el-form-item label="使用模板" prop="model">
                            <el-select v-model="certificateForm.model" clearable  placeholder="请选择证书模板" @change="certificateModel" style="width: 500px" >
                                <el-option
                                        v-for="item in certificateList"
                                        :key="item.id"
                                        :label="item.certificate_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择学生" prop="score" v-if="this.$route.query.batch">
                        分数 <el-input-number v-model="certificateForm.score" :precision="0" :controls="false"  style="width:120px;margin-right: 10px"></el-input-number>分以上的学生
                        </el-form-item>
                    </div>
                    <div class="certificate-title">
                        <span>编辑证书信息</span>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="证书名称"  prop="certificate_name" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.certificate_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入证书名称"></el-input>
                        </el-form-item>
                        <el-form-item label="证书名称（英文）"  prop="english_name" class="certificate-form-right-en" >
                            <el-input v-model="certificateForm.english_name" autocomplete="off" style="width: 500px"
                                      placeholder="请输入证书名称（英文）"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="评价机构名称"  prop="evaluate_name" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.evaluate_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入评价机构名称"></el-input>
                        </el-form-item>
                        <el-form-item label="评价机构名称（英文）"  prop="evaluate_english_name" class="certificate-form-right-en">
                            <el-input v-model="certificateForm.evaluate_english_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入评价机构名称（英文）"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="证书信息查询网址"  prop="information " class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.information" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入证书信息查询网址"></el-input>
                        </el-form-item>
                        <el-form-item label="机构信息查询网址"  prop="organization" class="">
                            <el-input v-model="certificateForm.organization" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入机构信息查询网址"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="姓名"  prop="account" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.account" autocomplete="off"  readonly style="width: 500px"
                                   ></el-input>
                        </el-form-item>
                        <el-form-item label="证件类型"  prop="id_type" class="">
                            <el-select v-model="certificateForm.id_type"  placeholder="请选择证件类型" style="width: 500px" >
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="证件号码名"  prop="id_num" class="certificate-form-left" >
                            <el-input class="certificate-input" v-model="certificateForm.id_num"   readonly  autocomplete="off" style="width: 500px"
                                    ></el-input>
                        </el-form-item>
                        <el-form-item label="职业名称"  prop="occupation_name" class="" style="width: 500px">
                            <el-input class="certificate-input" v-model="certificateForm.occupation_name" autocomplete="off" style="width: 500px"
                                      placeholder="请输入职业名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="工种/职业方向"  prop="job" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.job" autocomplete="off" style="width: 500px"
                                      placeholder="请输入工种/职业方向"></el-input>
                        </el-form-item>
                        <el-form-item label="职业技能等级"  prop="skill_level" class="" style="width: 500px">
                            <el-input class="certificate-input" v-model="certificateForm.skill_level" autocomplete="off" style="width: 500px"
                                      placeholder="请输入职业技能等级"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="证件编码"  prop="certificate_num " class="certificate-form-left" >
                            <div style="display: flex;justify-content: space-between">
                                <el-input-number v-model="certificateForm.certificate_num" :precision="0" :controls="false" placeholder="请输入证件编码" style="width: 390px;margin-right: 10px"></el-input-number>
                                <el-button type="primary"  @click="randomGeneration()">随机生成</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item label="分支机构名称"  prop="f_organization" class="" style="width: 500px">
                            <el-input class="certificate-input" v-model="certificateForm.f_organization" autocomplete="off" style="width: 500px"
                                      placeholder="请输入分支机构名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="发证日期"  prop="dateTimeStamp" class="certificate-form-right">
                            <el-date-picker
                                    style="width: 500px"
                                    v-model="certificateForm.dateTimeStamp"
                                    type="date"
                                    value-format="timestamp"
                                    placeholder="选择日期"
                                    @change="dateTimeChange"
                                    :picker-options="pickerOptions0">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="二维码" prop="er_code" class="certificate-form-right certificate-form-cod">
                            <template>
                                <el-button type="primary" class="t-btn-purple cod" @click="getCodeBtn('certificateForm')">随机生成二维码</el-button>
                            </template>
                        </el-form-item>
                    </div>
                    <div class="certificate-item">
                        <ViewCertificateModule :obj="certificateForm"></ViewCertificateModule>
                    </div>
                </el-form>
                <div class="btn-footer">
                    <el-button   @click="toBack()">返回</el-button>
                    <el-button  type="primary" @click="submitCertificate('certificateForm')">生成证书</el-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {certificateTemplateList,certificateWhetherTemplate,certificateInfo,certificate_createERCode,studentCertificate,batchCreateCertificate} from '@/utils/apis'
    import ViewCertificateModule from '../../../components/teacher/certificateContent/ViewCertificateModule.vue'
    export default {
        name: "GenerateCertificate",
        components: {
            ViewCertificateModule
        },
        data(){
            return{
                //限制当天前的时间不可点击
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                // role:'',//角色
                certificateForm:{
                    score:void 0,
                    model:'',
                    certificate_name:'',
                    english_name:'',
                    evaluate_name:'',
                    evaluate_english_name:'',
                    information:'',//证书信息查询网址
                    organization :'',//机构信息查询网址
                    account :this.$route.query.nickname||null,//姓名
                    id_type:'身份证',//身份证
                    id_num:this.$route.query.id_num||null,//身份证号
                    avatar:this.$route.query.avatar||null,//证件照
                    occupation_name:'',//职业名称
                    job:'',//工种/职业方向
                    skill_level:'',//职业技能等级
                    certificate_num:'',//证件编码
                    f_organization:'',//分支机构名称
                    organizationType:'',//分支机构类型
                    date_time:'',//发证日期
                    dateTimeStamp: '',
                    qr_code: '',//传给后端的二维码地址，相对路径
                    qr_code_img: '',//传给后端的二维码地址，完整路径
                },
                examId:this.$route.query.exam_id || null,//考试id
                studentId:this.$route.query.student_id || null,//当前需要学生生成证书的学生id
                noStudentId:this.$route.query.no_student_id || null,//未获得证书的学生id
                examName:this.$route.query.exam_name || null,//考试名称
                options: [{
                    value: '身份证',
                    label: '身份证'
                }],
                certificateList:[],
                idTypeList:[],
                rules: {
                    // model: [
                    //     {required: true, message: '请选择证书模板', trigger: 'change'},
                    // ],
                    certificate_name: [
                        {required: true, message: '请输入证书名称', trigger: ['blur', 'change']},
                        {min: 2, max: 10, message: '长度在 2 到 10个字符', trigger: 'blur'}
                    ],
                    english_name: [
                        {required: true, message: '请输入证书英文名称', trigger: 'blur'},
                        {min: 2, max:46, message: '长度在 2 到 46个字符', trigger: 'blur'}
                    ],
                    evaluate_name: [
                        {required: true, message: '请输入评价机构', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10个字符', trigger: 'blur'}
                    ],
                    evaluate_english_name: [
                        {required: true, message: '请输入评价机构（英文）', trigger: 'blur'},
                        {min: 2, max:30, message: '长度在 2 到 30个字符', trigger: 'blur'}
                    ],
                    information: [
                        {required: true, message: '请输入证书信息查询网址', trigger: 'blur'},
                    ],
                    organization: [
                        {required: true, message: '请输入机构信息查询网址', trigger: 'blur'},
                    ],
                    // account: [
                    //     {required: true, message: '请输入姓名', trigger: 'blur'},
                    //     {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    // ],
                    id_type: [
                        {required: true, message: '请输入证件类型', trigger: 'change'},
                    ],
                    // id_num: [
                    //     {required: true, message: '请输入证件号码名', trigger: 'blur'},
                    //     {min: 3, max: 18, message: '长度在 3 到 18 个字符', trigger: 'blur'}
                    // ],
                    occupation_name: [
                        {required: true, message: '请输入职业名称', trigger: 'blur'},
                        {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ],
                    job: [
                        {required: true, message: '请输入工种/职业方向', trigger: 'blur'},
                        {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ],
                    skill_level: [
                        {required: true, message: '请输入职业技能等级', trigger: 'blur'},
                        {min: 2, max: 3, message: '长度在 2 到 3 个字符', trigger: 'blur'}
                    ],
                    certificate_num: [
                        {required: true, message: '请输入证件编码', trigger: ['blur', 'change']},
                    ],
                    // f_organization: [
                    //     {required: true, message: '请输入分支机构名称', trigger: 'blur'},
                    //     {min: 3, max: 40, message: '长度在 3 到 40 个字符', trigger: 'blur'}
                    // ],
                    dateTimeStamp: [
                        {required: true, message: '请选择证书日期', trigger: 'change'}
                    ],

                },
            }
        },
        mounted() {
            this.getCertificateTemplate()
            if (this.$route.query.certificate_id) {
                this.getStudentCertificate()
            }
        },
        // created() {
        //     this.role = localStorage.getItem('role');
        // },
        methods:{
            //获取证书模板列表
            getCertificateTemplate(){
                certificateTemplateList().then((res) => {
                    this.certificateList = res.data.data ? res.data.data : []
                }).catch((err) => {
                    console.log(err);
                });
            },
            //模板选择事件
            certificateModel(e){
                this.certificateForm.model=e
                this.getCertificateShowTemplate()
            },
            //获取学生证书详细数据
            getStudentCertificate(){
                let params = {
                    certificate_id:this.$route.query.certificate_id
                }
                studentCertificate(params).then((res) => {
                    // this.certificateForm = res.data;
                    this.certificateForm.certificate_name = res.data.certificate_name
                    this.certificateForm.english_name = res.data.certificate_ename;
                    this.certificateForm.evaluate_name = res.data.appraise_name;
                    this.certificateForm.evaluate_english_name = res.data.appraise_ename;
                    this.certificateForm.information = res.data.appraise_info_site;
                    this.certificateForm.organization = res.data.certificate_ename;
                    this.certificateForm.occupation_name = res.data.work_name;
                    this.certificateForm.job = res.data.work_type;
                    this.certificateForm.skill_level = res.data.work_class;
                    this.certificateForm.certificate_num = res.data.certificate_num;
                    this.certificateForm.id_num = res.data.id_num;
                    this.certificateForm.f_organization = res.data.branch_organiza_name;
                    this.certificateForm.dateTimeStamp = res.data.certificate_time * 1000;
                    this.certificateForm.model = res.data.certificate_template > 0 ?res.data.certificate_template: null;
                    this.certificateForm.account = res.data.username;
                    this.certificateForm.id_type = res.data.certificate_type;
                    this.certificateForm.avatar = res.data.certificate_avatar;
                    this.certificateForm.id_num = res.data.id_num;
                    this.certificateForm.qr_code = res.data.qr_code;
                    this.timeFormat(res.data.certificate_time * 1000);
                }).catch((err) => {
                    console.log(err);
                });
            },
            // 获取证书详情
            getCertificateShowTemplate(){
                let params = {
                    certificate_template_id: this.certificateForm.model
                }
                certificateWhetherTemplate(params).then((res) => {
                    this.certificateForm.model = res.data.id;
                    this.certificateForm.certificate_name = res.data.certificate_name;
                    this.certificateForm.english_name = res.data.certificate_ename;
                    this.certificateForm.evaluate_name = res.data.appraise_name;
                    this.certificateForm.evaluate_english_name = res.data.appraise_ename;
                    this.certificateForm.information = res.data.certificate_info_site;
                    this.certificateForm.organization = res.data.appraise_info_site;
                    this.certificateForm.occupation_name = res.data.work_name;
                    this.certificateForm.job = res.data.work_type;
                    this.certificateForm.skill_level = res.data.work_class;
                    this.certificateForm.certificate_num = res.data.certificate_num;
                    this.certificateForm.f_organization = res.data.branch_organiza_name;
                    this.certificateForm.organizationType = res.data.type;
                    this.certificateForm.dateTimeStamp = res.data.certificate_time * 1000;
                    this.timeFormat(res.data.certificate_time * 1000)
                })
            },
            //生成证书
            submitCertificate(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.$route.query.batch) {
                            // 批量
                            let params = {
                                certificate_name: this.certificateForm.certificate_name,
                                certificate_ename: this.certificateForm.english_name,
                                appraise_name: this.certificateForm.evaluate_name,
                                appraise_ename: this.certificateForm.evaluate_english_name,
                                certificate_info_site: this.certificateForm.information,
                                appraise_info_site: this.certificateForm.organization,
                                work_name: this.certificateForm.occupation_name,
                                work_type: this.certificateForm.job,
                                work_class: this.certificateForm.skill_level,
                                certificate_num: this.certificateForm.certificate_num,
                                branch_organiza_name: this.certificateForm.f_organization,
                                certificate_time: parseInt(this.certificateForm.dateTimeStamp / 1000),
                                // certificate_template: this.certificateForm.model,
                                // certificate_type: this.certificateForm.id_type,
                                score_num: this.certificateForm.score,
                                exam_id: this.examId,
                            };
                            batchCreateCertificate(params)
                                .then((res) => {
                                    this.$message.success(res.msg);
                                    window.open(res.data)
                                    this.$router.push({
                                        path: "/evaluationCenter/getCertificateList",
                                        query: {
                                            exam_id: this.examId, //考试id
                                            // no_student_id:noStr,//未获得证书的学生id
                                            // exam_name: this.examName, //考试名称
                                        },
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else {
                            let params = {
                                certificate_name: this.certificateForm.certificate_name,
                                certificate_ename: this.certificateForm.english_name,
                                appraise_name: this.certificateForm.evaluate_name,
                                appraise_ename: this.certificateForm.evaluate_english_name,
                                certificate_info_site: this.certificateForm.information,
                                appraise_info_site: this.certificateForm.organization,
                                work_name: this.certificateForm.occupation_name,
                                work_type: this.certificateForm.job,
                                work_class: this.certificateForm.skill_level,
                                certificate_num: this.certificateForm.certificate_num,
                                branch_organiza_name: this.certificateForm.f_organization,
                                certificate_time: parseInt(this.certificateForm.dateTimeStamp / 1000),
                                certificate_template: this.certificateForm.model,
                                username: this.certificateForm.account,
                                certificate_type: this.certificateForm.id_type,
                                id_num: this.certificateForm.id_num,
                                student_id: this.studentId,
                                exam_id: this.examId,
                                certificate_avatar: this.certificateForm.avatar,
                                qr_code: this.certificateForm.qr_code,
                            };
                            if (this.$route.query.certificate_id) {
                                params.id = parseInt(this.$route.query.certificate_id);
                            }
                            certificateInfo(params)
                                .then((res) => {
                                    this.$message.success(res.msg);
                                    //从未生成证书的学生id中删除当前生成证书的学生id
                                    // let arr = this.noStudentId.split(",");
                                    // let index = arr.indexOf(this.student)
                                    // if(index > -1){
                                    //     arr.splice(index,1);
                                    // }
                                    // let noStr =  arr.join(",")
                                    this.$router.push({
                                        path: "/evaluationCenter/getCertificateList",
                                        query: {
                                            exam_id: this.examId, //考试id
                                            // no_student_id:noStr,//未获得证书的学生id
                                            // exam_name: this.examName, //考试名称
                                        },
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            toBack(){
                this.$router.push({
                    path: '/evaluationCenter/noGetCertificateList',
                    query:{
                        exam_id:this.examId,//考试id
                        // no_student_id:this.noStudentId,//未获得证书的学生id
                        // exam_name:this.examName,//考试名称
                    }
                })
            },
            //随机生成
            randomGeneration(){
                this.certificateForm.certificate_num = '';
                for (let i = 0; i < 12; i++) {
                    this.certificateForm.certificate_num += Math.floor(Math.random() * 10);
                }
            },
            //转时间戳
            dateTimeChange(val) {
                this.timeFormat(val)
            },
            timeFormat(dateTimeStamp){
                // 日期选择器确认
                let time = new Date(dateTimeStamp)
                let y = time.getFullYear();
                let m = time.getMonth()>8?time.getMonth()+1:'0'+(time.getMonth()+1);
                let d = time.getDate()>9?time.getDate():'0'+time.getDate();
                this.certificateForm.date_time = y+'年'+m+'月'+d+'日';
                // this.certificateForm.dateTimeStamp = val;
                let ye = time.toDateString().split(" ")[3];
                let me = time.toDateString().split(" ")[1];
                let de = parseInt(time.toDateString().split(" ")[2])
                this.certificateForm.date_etime = ''+ me +' '+ de + ',' + ye;
            },
            //获取二维码
            getCodeBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            certificate_name: this.certificateForm.certificate_name,
                            certificate_ename: this.certificateForm.english_name,
                            appraise_name: this.certificateForm.evaluate_name,
                            appraise_ename: this.certificateForm.evaluate_english_name,
                            certificate_info_site: this.certificateForm.information,
                            appraise_info_site: this.certificateForm.organization,
                            work_name: this.certificateForm.occupation_name,
                            work_type: this.certificateForm.job,
                            work_class: this.certificateForm.skill_level,
                            certificate_num: this.certificateForm.certificate_num,
                            branch_organiza_name: this.certificateForm.f_organization,
                            certificate_time: parseInt(this.certificateForm.dateTimeStamp / 1000),
                            certificate_template: this.certificateForm.model,
                            username: this.certificateForm.account,
                            certificate_type: this.certificateForm.id_type,
                            id_num: this.certificateForm.id_num,
                            student_id: this.studentId,
                            exam_id: this.examId,
                            certificate_avatar: this.certificateForm.avatar,
                        };
                        if (this.$route.query.certificate_id) {
                            params.id = parseInt(this.$route.query.certificate_id);
                        }
                        certificate_createERCode(params)
                            .then((res) => {
                                this.$message.success(res.msg);
                                this.certificateForm.qr_code = res.data.url;
                                this.certificateForm.qr_code_img = res.data.src;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .generate-certificate{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .certificate-content {
            height: 100%;
            background: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .certificate-content-item{
                padding: 0 60px;
                margin-top: 20px;
                .certificate-title {
                    font-size: 20px;
                    padding-left: 15px;
                    line-height: 1;
                    margin: 40px 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 5px;
                        height: 20px;
                        background: #2DC079;
                        border-radius: 3px;
                        transform: translateY(-50%);
                    }
                }
                .certificate-info {
                    display: flex;
                    justify-content: flex-start;
                    ::v-deep .el-input__inner {
                        height: 40px;
                        line-height: 40px;
                    }
                    ::v-deep .el-form-item__label {
                        color: #333;
                    }

                    .certificate-form-left {
                        margin-right: 90px;
                    }
                    .certificate-form-cod {
                        display: flex;

                        ::v-deep .el-form-item__label {
                            width: 330px !important;
                        }
                        ::v-deep .el-form-item__content{
                            margin-left:0 !important;
                        }
                    }
                    ::v-deep .el-input-number {
                        .el-input__inner {
                            text-align: left;
                        }
                    }
                    /*.certificate-form-right-en{*/
                    /*    ::v-deep .el-form-item__label{*/
                    /*        width: 135px!important;*/
                    /*    }*/
                    /*    ::v-deep .el-form-item__content{*/
                    /*        margin-left: 135px!important;*/
                    /*    }*/
                    /*}*/
                }
            }
            .btn-footer{
                text-align: center;
                margin: 20px 0;
            }
        }
    }

</style>
