<template>
    <div class="certificate-content clearFix">
        <div class="certificate-box"  id="pdfDom" ref="pdfDom">
            <div class="certificate-info">
                <div class="certificate-info-left">
                    <div class="certificate-title">
                        <p class="top"  v-if="obj.certificate_name">{{obj.certificate_name}}</p>
                        <p class="bottom" v-if="obj.english_name">{{obj.english_name}}</p>
                    </div>
                    <div class="certificate-wrapper">
                        <div class="mechanism-text">
                            <p class="top">
                                本证书由<span class="mechanism-name" v-if="obj.evaluate_name">{{obj.evaluate_name}}</span>
                                <span class="mechanism-name blank-name" v-else></span>颁发,
                                表明持证人通过本机构组织的职业技能等级认定具备该职业（工种）相应技能等级水平。
                            </p>
                            <p class="bottom">
                                This is to certify that the bearer has dcmonstatcd  corresponding compctency in
                                this occupation(job) for successful completion  of the occupational skill level assessment
                                organized  by <span v-if="obj.evaluate_english_name" class="mechanism-name">{{obj.evaluate_english_name}}</span>
                                <span class="mechanism-name blank-name" v-else></span>.
                            </p>
                        </div>
                        <div class="evaluate-title">
                            <div class="text" >
                                <span class="mechanism-name" v-if="obj.evaluate_name">{{obj.evaluate_name}}</span>
                                <span class="mechanism-name blank-name" v-else ></span>
                                <span> （评价机构名称）</span>
                            </div>
                            <div v-if="!obj.f_organization" style="font-size: 12px">
                                <p style="font-family:_GB2312">发证日期：{{obj.date_time}}</p>
                                <p style="font-family:'Times New Roman'">Issue date：{{obj.date_etime}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="website">
                        <p class="top">证书信息查询网址（Certificate information）:{{obj.information}}</p>
                        <p class="bottom">机构信息查询网址（Organization information）:{{obj.organization}}</p>
                        <p class="left">No.{{obj.certificate_num}}</p>
                    </div>
                </div>
                <div class="certificate-info-right" :class="obj.certificate_name ? '' : 'no-certificate-name'">
                    <div class="certificate-img">
                        <div class="avatar-img">
                            <img :src="obj.avatar" alt="" v-if="obj.avatar">
                            <img v-else src="../../../assets/images/teacher/default-header.png" alt="">
                        </div>
                        <div class="code-img" v-if="obj.qr_code_img">
                            <img :src="obj.qr_code_img" alt="">
                        </div>
                        <div class="code-img" v-else>
                            <img src="../../../assets/images/teacher/code.png" alt="">
                        </div>
                    </div>
                    <div class="certificate-msg">
                        <div class="form-item">
                            <p> 姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</p>
                            <el-input v-model="obj.account" autocomplete="off" style="width:440px" readonly></el-input>
                        </div>
                        <div class="english-title">Name</div>
                        <div class="form-item">
                            <p> 证件类型：</p>
                            <el-input v-model="obj.id_type" autocomplete="off" style="width:400px" readonly></el-input>
                        </div>
                        <div class="english-title">ID Type</div>
                        <div class="form-item">
                            <p> 证件编号：</p>
                            <el-input v-model="obj.id_num" autocomplete="off" style="width:400px" readonly></el-input>
                        </div>
                        <div class="english-title">ID NO.</div>
                        <div class="form-item">
                            <p> 职业名称：</p>
                            <el-input v-model="obj.occupation_name" autocomplete="off" style="width:400px" readonly></el-input>
                        </div>
                        <div class="english-title">Occupation</div>
                        <div class="form-item">
                            <p> 工种/职业方向：</p>
                            <el-input v-model="obj.job" autocomplete="off" style="width:360px" readonly></el-input>
                        </div>
                        <div class="english-title">Job</div>
                        <div class="form-item">
                            <p>职业技能等级：</p>
                            <el-input v-model="obj.skill_level" autocomplete="off" style="width:370px" readonly></el-input>
                        </div>
                        <div class="english-title">Skill Level</div>
                        <div class="form-item">
                            <p>证书编号：</p>
                            <el-input v-model="obj.certificate_num" autocomplete="off" style="width:400px" readonly></el-input>
                        </div>
                        <div class="english-title">Certificate NO.</div>
                    </div>
                    <div class="data" v-if="obj.f_organization">
                        <p class="">{{obj.f_organization}}（分支机构名称）</p>
                        <p>发证日期：{{obj.date_time}}</p>
                        <p>Issue date：{{obj.date_etime}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ViewCertificateModule",
        props: {
            obj: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                htmlTitle: '证书PDF',
                printObj: {
                    id: "pdfDom",
                    popTitle: '证书PDF',
                }
            }
        },
        watch:{
            obj:{
                handler:function (val) {
                    this.$forceUpdate()
                },
                deep:true
            }
        },
        methods:{
        }
    }
</script>

<style scoped lang="scss">
    .certificate-content{
        width: 1300px;
        height: 0;
        padding-bottom:816px;
        position: relative;
        margin: 0 auto;
        .certificate-box{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background: url("../../../assets/images/teacher/certificate-bg.png") no-repeat;
            background-size: 100% 100%;
            padding: 30px 90px;
            box-sizing: border-box;
            .certificate-info{
                display: flex;
                justify-content: flex-start;
                .certificate-info-left{
                    width: 50%;
                    text-align: justify;
                    .certificate-title{
                        margin: 48px 0 40px 36px;
                        height: 98px;
                        .top{
                            font-size: 30pt;
                            font-family: _GB2312;
                            margin-bottom: 20px;
                            letter-spacing: 13px;
                            height: 52px;
                            overflow: hidden;
                            text-align: center;
                        }
                        .bottom{
                            font-size: 16pt;
                            letter-spacing:4px;
                            font-family:'Times New Roman';
                            height: 25px;
                            overflow: hidden;
                            text-align: center;
                        }
                    }
                    .certificate-wrapper{
                        .mechanism-text{
                            height: 324px;
                            .top{
                                word-break: break-word;
                                font-size: 16pt;
                                letter-spacing: 6px;
                                margin-bottom: 30px;
                                font-weight: 600;
                                line-height: 2;
                                text-indent: 36px;
                                font-family: _GB2312;
                            }
                            .bottom{
                                line-height: 2;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp:5;
                                font-size: 16pt;
                                font-weight: 600;
                                text-indent: 36px;
                                font-family: "Times New Roman";
                            }
                        }
                    }
                    .evaluate-title{
                        font-size: 12pt;
                        font-weight: 600;
                        display: flex;
                        flex-direction: column;
                        height: 100px;
                        line-height: 2;
                        margin: 14px auto;
                        width:320px;
                        .text{
                            display: flex;
                            font-family: _GB2312;
                            font-size: 12pt;
                        }
                    }
                    .website{
                        width: 100%;
                        font-family: _GB2312;
                        font-size: 11pt;
                        height: 88px;
                        .bottom{
                            margin: 10px 0;
                        }
                        .left{
                            margin-top: 20px;
                            font-family:'Times New Roman';
                            font-size: 12pt;
                        }
                    }
                }
                .certificate-info-right{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-left: 90px;
                    box-sizing: border-box;
                    .certificate-img{
                        display: flex;
                        align-items: center;
                        height: 262px;
                        .avatar-img{
                            width:110px;
                            height: 153px;
                            img{
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .code-img{
                            width: 130px;
                            height: 130px;
                            padding-left: 90px;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .certificate-msg{
                        width: 100%;
                        .form-item{
                            display: flex;
                            align-items: center;

                            .form-item-list{

                            }
                            ::v-deep .el-input__inner{
                                border-right: none;
                                border-left: none;
                                border-top: none;
                                height: auto;
                                line-height: 0;
                                border-radius: inherit;
                                background: transparent;
                            }
                            p{
                                min-width: 46px;
                            }
                        }
                        .english-title{
                            margin: 10px 0;
                        }
                    }

                    .data{
                        position: relative;
                        bottom: 15px;
                        margin: 0 auto;
                        height: 100px;
                        width: 230px;
                        line-height: 2;
                    }

                }
                .mechanism-name{
                    text-decoration: underline;
                }
                p{
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .clearFix:after{
            content: "";
            display: block;
            height: 0;
            clear:both;
            visibility: hidden;
        }
        .clearFix{
            *zoom: 1;
        }
    }
    .blank-name {
        width: 80px;
        display: inline-block;
        border-bottom: 2px solid #333;
        margin-right: 8px;
    }
    .no-certificate-name {
        position: relative;
        top: 50px;
    }
</style>
